import {
    reqApi
} from '@/api/utils/reqApi.js'
import
linkAPP
from '@/utils/linkApp.js'
// 组件
import {
    isWeixin,
    isIos
} from "@/utils/common";
import { appOptions } from "@/config/settingConfig/web.js";

import {
    Toast
} from 'vant'
export const mixinLogin = {
    data() {
        return {
            formData: {
                phone: "",
                code: "",
            },
            state: {
                sendSmsMessage: "发送验证码",
                code: "",
            },
        }
    },
    created() {
        console.log(`来自Mixins中的消息`)
    },

    methods: {
        handleSubmit(values) {
            if (values) {
                if (this.formData.phone && this.formData.code) {
                    const {
                        deptId
                    } = this.$route.query

                    // 补充机构id
                    // 报名接口
                    const submitData = {
                        deptId,
                        ...this.formData
                    }
                    this.submitAPI(submitData)
                    console.log(deptId, this.formData, 'handleSubmit', values, this.$route.query.deptId)
                }
            }
        },

        /**api */
        async submitAPI(apiParams) {
            const res = await reqApi("codeLogin", {
                ...apiParams
            });
            // console.log(res, "submitAPI");
            if (res.code == 200) {
                Toast.success(res.msg || "报名成功");
                // 打开app
                if (isIos()) {
                    linkAPP()
                } else {
                    let downLoadUrl = appOptions.downLoadUrl;
                    window.location.href = downLoadUrl;
                }
            }
        },

        sendSms(name) {
            console.log(name, 'sendSms')
            this.manualValidate("phone");
        },

        manualValidate(params) {
            const {
                phone,
                code
            } = this.formData
            switch (params) {
                case "phone":
                    const rules = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                    // 手动主动验证表单
                    if (rules.test(phone)) {
                        this.sendSmsApi()
                    } else {
                        Toast('请填写正确的手机号!')
                    }
                    break;

            }

        },

        async sendSmsApi() {
            if (this.state.sendSmsMessage != "发送验证码") {
                return;
            }
            this.VerificationCountdown(60);
            // clearStore("Authorization")
            let res = await reqApi("sendSmsUser", {
                phone: this.formData.phone
            });
            console.log(res.data.code, "sendSms");
            this.state.code = res.data.code;
        },
        // 倒计时
        VerificationCountdown(time) {
            let self = this
            let inter = setInterval(() => {
                self.state.sendSmsMessage = time + "s";
                time--;
                if (time < 0) {
                    clearInterval(inter);
                    self.state.sendSmsMessage = "发送验证码";
                    self.state.code = "";
                }
                console.log(self.state.sendSmsMessage, "*****");
            }, 1000);
        },
        // 表单值变化
        inputChange(record, fieldItem) {
            const {
                isTirm,
                name
            } = fieldItem
            if (isTirm) {
                let currVal = record.replace(/\s*/g, ""); //去内容中空格
                currVal = currVal.replace(/[^\d]/g, '')
                switch (name) {
                    case "phone":
                        this.formData.phone = currVal
                        break;
                    case "code":
                        this.formData.code = currVal
                        break;
                }
            }
        }

    },
}