export const shareData = {
    tagList: [{
        id: '01',
        tagName: '全真考试模拟',
        des: '考级无忧'
    }, {
        id: '02',
        tagName: '专业机构认证',
        des: '学习无忧'
    }, {
        id: '03',
        tagName: '智能管理平台',
        des: '操作无忧'
    }]
}


export const fullForm = {
    fieldData: {
        phone: {
            model: "phone",
            required: true,
            readonly: false,
            clickable: false,
            name: "phone",
            label: "手机号",
            placeholder: "请填写手机号",
            isTirm: true,
            maxlength: 11,
            rules: [{
                    required: true,
                    message: ''
                },
                {
                    pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                    message: '请填写正确的手机号'
                }
            ]
        },

        code: {
            model: "code",
            required: true,
            readonly: false,
            clickable: false,
            name: "code",
            label: "",
            placeholder: "验证码",
            fieldType: "code",
            isTirm: true,

            rightIcon: null,
            maxlength: 4,
            rules: [{
                required: true,
                message: ''
            },{
                pattern: /^\d{4}$/,
                message: '请输入正确的验证码'
            }]
        },
    }
}