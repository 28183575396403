<template>
  <div class="shareEntryPage text-base">
    <div class="share_nav_wrap bg_share_nav">
      <div class="app_name text-white fw7 tracking-wider tc pt-5 pb-2">
        <h5>{{appOptions.name}}APP</h5>
      </div>
      <div class="flex-cc">
        <span class="brown_text_1 px-1 py-0.5 bg_theme_linear rounded bg_theme_line">
          考级培训学习一体化服务
        </span>
      </div>

    </div>
    <ul class="share_nav_secord bg_share_nav_secord px-2.5 flex-rb">
      <li class="tc flex-cc" v-for="v in tagList" :key='v.id'>
        <div class="">
          <div class="py-1 bg_tag_nav text-white rounded-sm">{{v.tagName}}</div>
          <p class="brown_text_1 pt-1 text-tiny">{{v.des}}</p>
        </div>
      </li>
    </ul>

    <div class="share_content_wrap">
      <h6 class="brown_text_2 tc text-tiny my-1">*技术支持 @南京恰好信息技术有限公司</h6>
      <div class="org_introduce px-2 py-3">
        <div class="bg_org_introduce bg-white fw7 mb-3">
          <div class="bg_org_introduce_head tc flex-cc text-base text-white">
            <span>请留下你的联系方式</span>
          </div>
          <van-form @submit='handleSubmit' ref='registerPageForm'>
            <van-field required v-model="formData.phone" label="手机号" maxlength="11" placeholder="请输入手机号"
              :rules="fullForm.fieldData['phone'].rules" @input="inputChange($event,fullForm.fieldData['phone'])" />
            <van-field required v-model="formData.code" center clearable maxlength="4" label="短信验证码"
              placeholder="请输入短信验证码" :rules="fullForm.fieldData['code'].rules"
              @input="inputChange($event,fullForm.fieldData['code'])">
              <template #button>
                <van-button size="small" type="primary" @click="sendSms">{{
                state.sendSmsMessage
              }}</van-button>
              </template>
            </van-field>
            <div class="mx-4 my-1">
              <van-button size='small' round block type="primary" native-type="submit">下载APP</van-button>
            </div>
          </van-form>

        </div>
        <div class="bg_org_introduce bg-white fw7 ">
          <div class="bg_org_introduce_head tc flex-cc text-base text-white">
            <span>相关介绍</span>
          </div>
          <div class="px-2">
            <p class="text_2em">{{detailInfo.introduction || appOptions.introduction}}</p>
            <span class="brown_text_1 tc flex-ac py-2">
              <i class="iconfont icon-diqu text-base pr-1"></i>
              <i class="text-tiny">{{detailInfo.introduction ? detailInfo.address : appOptions.address}}</i>
            </span>
          </div>

        </div>
      </div>

    </div>

    <HOverlay :showOverlay='showOverlay'>
      <template v-slot:innerBox>
        <div class="">
          <CShareMask />
        </div>
      </template>
    </HOverlay>

    <!-- <HFooterBtn :options='HFooterBtnOptions' @ok='handleEnter' /> -->

  </div>
</template>

<script>
import { appOptions } from "@/config/settingConfig/web.js";
import { isWeixin, isIos } from "@/utils/common";
import { shareData } from "./index.js";
// components
import { HFooterBtn, HOverlay } from "@/components/HVant";
import { CShareMask } from "@/components/comm";

import { fullForm } from "./index.js";
// hooks
import { mixinLogin } from "@/hooks/mixin/login.js";
import { reqApi } from "@/api/utils/reqApi.js";
import { Toast } from "vant";
export default {
  name: "shareEntryPage",
  mixins: [mixinLogin],
  components: {
    HFooterBtn,
    HOverlay,
    CShareMask,
  },
  props: {},
  data() {
    return {
      fullForm,
      appOptions,
      isWeixin: isWeixin(),
      tagList: shareData.tagList,
      HFooterBtnOptions: {
        text: "进入APP",
      },
      detailInfo: {},
      showOverlay: false,
    };
  },

  mounted() {
    this.loadDetailAPI();
    if (!isIos()) {
      // let downLoadUrl = appOptions.downLoadUrl;
      // window.location.href = downLoadUrl;
    }

    this.showOverlay = !!this.isWeixin;
    // Toast(this.showOverlay + "是否微信环境");
  },

  methods: {
    handleEnter() {
      console.log("进入app");
    },
    async loadDetailAPI() {
      const id = this.$route.query.deptId;
      if (!id) {
        console.log("没有机构认证");
        return;
      }
      let { data } = await reqApi("deptInfoDetail", id, "paramsJoin");
      if (data) {
        this.detailInfo = data;
      }
    },
  },
  filters: {},
  // computed: {
  //    showOverlay: {
  //     get() {
  //       //  console.log(isWeixin(),'isWeixin')
  //       const isOpen = !!this.isWeixin
  //        Toast(isOpen+'123')
  //        return isOpen
  //      }
  //    },
  // },
};
</script>
<style lang="less" src='./index.less'>
</style>


